import * as React from "react"
import { Container, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
//@ts-ignore
import NotFound from "../images/404.svg"

import Layout from "../components/Layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query NotFoundQuery {
      file(name: { eq: "404" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 1280, formats: AUTO)
        }
      }
    }
  `)
  const image = getImage(data.file)
  console.log(data, image)
  return (
    <Layout>
      <Seo title="404: Not found" />
      <Container
        maxWidth="md"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexFlow: "column",
        }}
      >
        <Typography variant="h1" gutterBottom>
          Not found...
        </Typography>
        <img
          src={NotFound}
          style={{ width: "100%", height: "auto", maxWidth: 400 }}
        />
      </Container>
    </Layout>
  )
}

export default NotFoundPage
